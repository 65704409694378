
import * as Yup from 'yup';
import React from 'react';
import {  useHistory } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { doAuth2} from '../../services/AuthService';


const Auth2 = () => {

  const history = useHistory()


  return (
    <main>

      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <div className="container">

          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center text-md-center mb-4 mt-md-0">

              </div>
              <Formik
                initialValues={{
                  code: '',
            

                }}
                validationSchema={
                  Yup.object({
                    code: Yup.string()
                      .required('Required *'),
                  })
                }

                onSubmit={ async (value:any) => {

                    const response = await doAuth2(value.code)

                    if(response.token) {
                      return setTimeout(() => {
                        const tokenStorage = localStorage.getItem('token')
                        if (tokenStorage) return history.push('/passwords')
                      }, 1000)
                    }
                    
                }}
              >

                <Form className="mt-4" noValidate>
                  <div className="form-group">
                    <div className="form-group mb-4">
                      <label htmlFor="code">Enter Your code</label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon2">
                          <svg className="icon icon-xs text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd"></path></svg>
                        </span>
                        <Field name="code" type="number" className="form-control" placeholder="000000" id="code" />
                      </div>
                      <div className="mt-1">
                        <small className="text-danger"> <ErrorMessage name='code' /></small>
                      </div>
                    </div>
                  </div>

                  <div className="d-grid">
                    <button type="submit" className="btn btn-gray-800">Sign in</button>
                  </div>

                </Form>

              </Formik>
            </div>
          </div>
        </div>

      </section>
    </main>
  )
}

export default Auth2