import Environment from '../environments'
import { authApi } from './api/Auth.api'


const url = Environment.apiUrl

export const checkinToken = async () => {
  const { data } = await authApi.get(`${url}/auth/checkin/token`)

  return data

}




