/* eslint-disable no-lone-blocks */
import Swal from 'sweetalert2'

interface Props {
  title: string
}

export const SwalSuccess = async ({ title }: Props) => {
  try {


    return Swal.fire({
      position: 'center',
      icon: 'success',
      title: title,
      showConfirmButton: false,
      timer: 1500
    })

  } catch (error) {
    
    return error
  }
}



export const SwalError = async ({ title }: Props) => {
  try {

    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: title,
      showConfirmButton: false,
      timer: 1500
    })

  } catch (error) {

  }
}

export const SwalLoadingSuccess = async ({ title }: Props) => {
  try {
    let timerInterval: any
    Swal.fire({
      title: title,
      html: 'I will close in <b></b> milliseconds.',
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        const b: any = Swal.getHtmlContainer()!.querySelector('b')
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft()
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        
      }
    })
  } catch (error) {

  }
}