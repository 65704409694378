/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"

import ADMIN_ROLE from "./ADMIN_ROLE"

const SideBar = () => {
  // const [chekinRole, setChekinRole] = useState('')

/*
  useEffect(() => {
    checkinToken().then( response => {
      setChekinRole(response.account.role)
    }).catch( error => {
      console.log(error)
    })
  }, [])

  */


  return (
    // (chekinRole === 'USER_ROLE') 
   // ? <USER_ROLE />
    //:
    <ADMIN_ROLE />
  )
}

export default SideBar