import React from "react"
import Navbar from "./Navbar"
import SideBar from "./SideBar"


const Menu = () => {
  return (
    <React.Fragment>
      <Navbar />
      <SideBar />
    </React.Fragment>
  )
}

export default Menu