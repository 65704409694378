/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Jumbotron from "../../components/shared/Jumbotron"
import Menu from "../../components/shared/Menu"
import {getPasswords} from "../../services/password/PasswordService"
import ModalDecodePassword from "./ModalDecodePassword"
import ModalDeletePassword from "./ModalDeletePassword"
import ModalEditPassword from "./ModalEditPassword"
import PasswordRow from "./PasswordRow"

const PasswordList = () => {

  const [passwordsList, setPasswordsList] = useState<any[]>([])
  const [showPasswordDecode, setShowPasswordDecode] = useState<any>({})

  const history = useHistory()
  const createPassword = async () => {
    history.push('/add-password')
  }

  const showPasswordClick= (event: any)=>{

    const password = event.target.id.replace('show', '') || event.target.id.replace('edit', '')
   
    const passwordObject = passwordsList.find( p => p.id === password)

    setShowPasswordDecode(passwordObject)
    return console.log('click ...')
  }

  

  useEffect(() => {
    getPasswords().then( response => {
      setPasswordsList(response.passwords)
    }).catch( err => console.log(err))
    
  }, [])


  
  return (
    <>
    <Menu />
    <main className="content">
      <Jumbotron title="Passwords" titleButton="Add Pass" action={createPassword} 
      icon={<svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>}/>


      <div className="row">
          <div className="col-12">
            <div className="col-12 md-4">
              <div className="card border-0 shadow">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="col-md-12 d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                      <h2 className="fs-5 fw-bold mb-0"> <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path> </svg> Total {passwordsList.length} </h2>

                      
                      </div>
                    </div>
                    
                  </div>
                  
                </div>
                
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                      <th className="border-bottom" scope="col"> Image</th>
                        <th className="border-bottom" scope="col"> Type</th>
                        <th className="border-bottom" scope="col"> Password</th>
                        <th className="border-bottom" scope="col"> Created at</th>
                        <th className="border-bottom" scope="col"> Go</th>

                        <th className="border-bottom" scope="col"> Actions</th>
                      </tr>
                    </thead>

                    <tbody >
                    
                    {
                      passwordsList.map( item => <PasswordRow  data={item} key={item.id } onClick={showPasswordClick}/> )
                    }

                    </tbody>
                  </table>
                </div>
              </div>

            </div>

          </div>

        </div>
      
</main>
        <ModalDecodePassword data={showPasswordDecode}/>
        <ModalEditPassword data={showPasswordDecode}/>
        <ModalDeletePassword data={showPasswordDecode}/>
    </>

    
  )
}


export default PasswordList