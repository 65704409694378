
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as Yup from 'yup';
import { useHistory } from "react-router-dom"
import Jumbotron from "../../components/shared/Jumbotron"
import Menu from "../../components/shared/Menu"
import { useState } from "react";
import { postPassword } from "../../services/password/PasswordService";
import { suggestionPassword } from "../../services/features/uuid";


const AddPassword = () => {
  const [error, setError] = useState('')

  const [suggestionPass, setSuggestionPass] = useState<any>()

  const history = useHistory()
  const goBack = () => {
    history.push('/passwords')


  }

  const passwordGeneration = async () => {
    const password = await suggestionPassword()
    const date = new Date().getMilliseconds()
    const transformUper = password.replace('-', '').slice(0,5).toUpperCase()
    const newPassword = password.replace('-', '').slice(23)+date+transformUper
    

    console.log(transformUper)
    
    setSuggestionPass(newPassword)
    console.log(password)
  }

  return (

    <>
      <Menu />
      <main className="content">


        <Jumbotron title="Create Password" titleButton="Back" action={goBack} icon={
          <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
        } />


        <div className="row">
          <div className="col-12 col-xl-12">
            <div className="card card-body border-0 shadow mb-4">

              <Formik initialValues={{
                href: '',
                seed: '',
                username: '',
                password: '',
                passwordConfirmation: '',
                secretKey: '3',
                type: '',

              }}

                validationSchema={
                  Yup.object({
                    href: Yup.string()
                    .matches(
                      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                      'Enter correct url!'
                  )
                  .required('Please enter website'),
                    seed: Yup.string()
                      .required('Required *'),
                    username: Yup.string()
                      .required('Required *'),
                    password: Yup.string()
                      .required('Required'),
                    passwordConfirmation: Yup.string()
                      .required('Required'),
                    type: Yup.string()
                      .required('Required *'),

                  })
                }

                onSubmit={async (values, { resetForm }) => {

                  if (values.password !== values.passwordConfirmation) return setError('Password and confirm password does not match')

                  await postPassword(values)
                  resetForm()
                  return history.push('/passwords')
                }}

              >

                <Form noValidate>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div>
                        <label htmlFor="type">Type</label>
                        <Field name="type"
                          className="form-control" type="text" placeholder="Enter your type" />
                        <small className="text-danger"> <ErrorMessage name='type' /></small>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                                    <label htmlFor="email">Link website</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1">
                                        <svg className="icon icon-xs text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"></path></svg>
                                        </span>
                                        <Field name="href"
                                   className="form-control" type="text" placeholder="Also your link" />
                                    </div>  
                                    <small className="text-danger"> <ErrorMessage name='href' /></small>
                                </div>

                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <Field name="username"
                          className="form-control" type="text" placeholder="username" />
                        <small className="text-danger"> <ErrorMessage name='username' /></small>
                      </div>
                    </div>
                    <div className="col-md-5 mb-3">
                      <div className="form-group">
                        <label htmlFor="password">Password
                        </label>

                        <Field name="password"
                          className="form-control" type="password" placeholder="password" />
                        <small className="text-danger"> <ErrorMessage name='password' /></small>
                        <div>
                          <small className="text-success"> {suggestionPass}</small>

                        </div>
                      </div>
                      
                    </div>
                    <div className="col-md-1 mb-3">
                      <div className="form-group">
                        <label htmlFor="password">Suggestion
                        </label>

                        <span className="btn btn-success mt-0 animate-up-2" onClick={passwordGeneration}>

                          <svg className="icon icon-xs" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"></path></svg>

                        </span>
                      </div>

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label htmlFor="passwordConfirmation">Confirm Password</label>
                        <Field name="passwordConfirmation"
                          className="form-control" type="password" placeholder="passwordConfirmation" />
                        <small className="text-danger"> <ErrorMessage name='passwordConfirmation' /></small>
                        <small className="text-danger"> {error}</small>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div>
                        <label htmlFor="seed">Secret SEED</label>

                        <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1">
                                        <svg className="icon icon-xs text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
                                        </span>
                                        <Field name="seed"
                          className="form-control" type="password" placeholder="Enter your secret" />
                                    </div>  

                        <small className="text-danger"> <ErrorMessage name='seed' /></small>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save</button>
                  </div>

                </Form>

              </Formik>
            </div>
          </div>

        </div>


      </main>

    </>

  )
}

export default AddPassword