import axios from "axios";
import Environment from "../../environments";


const baseUrl = Environment.apiUrl
export const authApi = axios.create({ baseURL: baseUrl })

authApi.interceptors.request.use(
        async (config: any) => {
        const token = localStorage.getItem('token');
        
        if ( token ) {
            config.headers['x-access-token']  = token
            
        }
        return config;
    }
)

