import { useHistory } from "react-router-dom"
import { authApi } from "../api/Auth.api"
import { SwalError, SwalSuccess } from "../swal"

export const createAccount = async ( values: any ) => {
  
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const response = await authApi.post(`/signup`, values)
    if(response.data) {
      
      return SwalSuccess({title: 'User registered successfully!!!'})
     
    }
  } catch (error: any) {
    
    if(error.response.data.message) return SwalError({title: error.response.data.message})
    return SwalError({title: error.response.data.name})
  }

}


export const getAccounts = async () => {

  try {

    const response = await authApi.get(`/accounts`)
    if(response.data) {
      
      return response.data
    }
  } catch (error: any) {
   
    return error
  }

}


export const deleteAccount= async ( id: string ) => {

  try {


    const response = await authApi.delete(`/account/`+ id)
    if(response.data) {
     
      return SwalSuccess({title: 'User deleted successfully!!!'})
    }
  } catch (error: any) {
    
    return SwalError({title: error.response.data.message})
  }

}

export const getAccountById= async ( id: string ) => {

  try {

    const response = await authApi.get(`/account/`+ id)
    
    return response.data
  } catch (error: any) {
      console.log(error)
    return error
  }

}

export const updateAccount = async (id: string, data: any) => {
  try {
    const response = await authApi.put(`/account/${id}`, data)
    
    if(response.data) {
     
      return SwalSuccess({title: 'Successfully!!!'})
    }
  } catch (error: any) {
    
    return SwalError({title: error.response.data.message})
  }
 
 }

 export const updateAccountSettings = async (id: string, data: any) => {
  try {
    const response = await authApi.put(`/account/${id}`, data)
    
    return response.data
  } catch (error) {
   console.log(error)
   return error
  }
 
 }