import { authApi } from "../api/Auth.api"
import { SwalError, SwalSuccess } from "../swal"

interface Props {

    id?: string
    seed: string
    secret?: string
    token?: string
    type?: string
    password: string
    href?: string
    passwordConfirmation?: string
    data?: JSX.Element | any
    createdAt?: string
  
}
export const getPasswords = async() => {
  try {

    const response = await authApi.get('/passwords/account_id')
    
    return response.data
  } catch (error) {
    
    return error
  }
}

export const getPasswordDecrypted = async(password_id: string, seed : Props) => {
  try {

    const response = await authApi.get(`/password/${password_id}/${seed}`)

    return response.data
    
    
  } catch (error) {
    
    return error
  }
}

export const editPassword = async(password_id: string, data: any) => {
  try {

    const response = await authApi.put(`/password/${password_id}`, data)

    return response.data
    
    
  } catch (error) {
    
    return error
  }
}



export const postPassword = async(data: any) => {
  try {

    const response = await authApi.post('/password/account_id', data)
    
    if(response.data) {
      
      return SwalSuccess({title: 'Password registered successfully!!!'})
     
    }
  } catch (error: any) {
    
    if(error.response.data.message) return SwalError({title: error.response.data.message})
    return SwalError({title: error.response.data.name})
  }
} 


export const removePassword = async(password_id: string, seed : Props) => {
  try {

    
    const response = await authApi.delete(`/password/${password_id}/${seed}`)

    return response.data
    
    
  } catch (error) {
    
    return error
  }
}