/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react"
import { Link, useHistory } from 'react-router-dom'
import Menu from "../../components/shared/Menu"
import { doLogOut } from "../../services/AuthService"
import { getGroupById, updateGroup } from "../../services/GroupService"
import { checkinToken } from "../../services/Settings"
import Swal from 'sweetalert2'

import { updateAccountSettings } from "../../services/account/AccountService"
interface Props {
  id: string
  name: string
  last_name: string
  email: string
  phone: number
  role: string

}


const Settings = () => {
  const [account, setAccount] = useState<Props>({
    id: '',
    name: '',
    last_name: '',
    email: '',
    phone: 0, 
    role: ''
  })
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const inputName: any = useRef('')
  const inputLastName: any = useRef('')
  const inputEmail: any = useRef('')
  const inputPhone: any = useRef('')
  const inputPassword: any = useRef('')
  const inputConfirmPassword: any = useRef('')


  const history = useHistory()

  useEffect(() => {

         checkinToken().then( response => {

          const {account} = response
          setAccount(account)
          inputName.current.value = account.name
          inputLastName.current.value = account.last_name
          inputEmail.current.value = account.email
          inputPhone.current.value = account.phone

        })
       

  }, [])



  const onSubmitAccount = async (event: any) => {

    try {
      event.preventDefault()

      let body = {
        name: inputName.current.value,
        last_name: inputLastName.current.value,
        email: inputEmail.current.value,
        phone: inputPhone.current.value,
        password: inputPassword.current.value,
        passwordConfirmation: inputPassword.current.value
  
      }
  
      if((inputPassword.current.value || inputConfirmPassword.current.value)
      && inputPassword.current.value !== inputConfirmPassword.current.value) {
        
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: 'Password and confirm password are not the same.'
        })
      }

      return Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Updated!',
            'Your information will be modified.',
            'success'
          )
          updateAccountSettings(account.id, body)
        }
      })
    } catch (error) {
      return setError(`Error in the inputs fields`)
    }


  }

 



  return (
    <main className="content">
       <Menu />
       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-3">
       <div className="d-block mb-4 mb-md-0">
       <h3>Settings</h3>
       </div>

       </div>
       <div className="row">
                <div className="col-12 col-xl-12">
                    <div className="card card-body border-0 shadow mb-4">
                        <h2 className="h5 mb-4">General information</h2>
                        <form onSubmit={onSubmitAccount}>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <label htmlFor="first_name">First Name</label>
                                        <input ref={inputName}
                                        className="form-control" id="first_name" type="text" placeholder="Enter your first name" required />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <label htmlFor="last_name">Last Name</label>
                                        <input ref={inputLastName}
                                        className="form-control" id="last_name" type="text" placeholder="Also your last name" required />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input ref={inputEmail}
                                        className="form-control" id="email" type="email" placeholder="name@company.com" required />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone</label>
                                        <input ref={inputPhone}
                                        className="form-control" id="phone" type="number" placeholder="+12-345 678 910" required />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <label htmlFor="first_name">Password</label>
                                        <input ref={inputPassword}
                                        className="form-control" id="inputPassword" type="password" placeholder="Enter your password" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <label htmlFor="last_name">Confirm Password</label>
                                        <input ref={inputConfirmPassword}
                                        className="form-control" id="inputConfirmPassword" type="password" placeholder="Also your password confirmation" />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save</button>
                            </div>
                        </form>


                    </div>
                </div>
                {
                  error ?
                    <div className="alert alert-danger mt-2"> {error}</div>
                    : <React.Fragment></React.Fragment>
                }
                {
                  success ?
                    <div className="alert alert-success mt-2"> {success}</div>
                    : <React.Fragment></React.Fragment>
                }
            </div>

            
    </main>
  )
}

export default Settings