import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Jumbotron from "../../components/shared/Jumbotron"
import Menu from "../../components/shared/Menu"
import { getLogsByAcccountId } from "../../services/logs/LogService"
import LogRow from "./LogRow"

const LogList = () => {

  const [logs, setLogs ] = useState<any>([])

  const history = useHistory()
  const goBack = () => {
    history.push('/passwords')

  }

  useEffect(() => {
    getLogsByAcccountId().then( (response) => {



      if(!response) return 

      setLogs(response.data)
      
    }).catch( err => console.log(err))
    
  }, [])


  return (
    <>
      <Menu />
      <main className="content">


        <Jumbotron title="Access Logs" titleButton="Back" action={goBack} icon={
          <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
        } />


        <div className="card border-0 shadow">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0 rounded">
                <thead className="thead-light">
                  <tr>
                    <th className="border-0 rounded-start">Ip</th>
                    <th className="border-0">Country</th>
                    <th className="border-0">Region</th>
                    <th className="border-0">City</th>
                    <th className="border-0">Date in</th>
                    <th className="border-0">Date Out</th>
                    <th className="border-0 rounded-end">Time</th>
                  </tr>
                </thead>
                <tbody>
                    {
                      logs!.map( (item: any) => <LogRow  data={item} key={item.id} /> )
                    }
                  
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </main>
    </>
  )
}

export default LogList