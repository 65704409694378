import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import LogList from "../private/Logs/LogList"
import AddPassword from "../private/Password/AddPassword"
import PasswordList from "../private/Password/PasswordList"
import Settings from "../private/Settings/Settings"
import Auth2 from "../public/Login/Auth2"
import Login from "../public/Login/Login"
import NotFound from "../public/NotFount/NotFound"

const ADMIN_ROLE_ROUTE = () => {


  const PrivateRoutes = ({ children, ...rest}: any) => {
  
    return (
         <Route  {...rest} render={ () => {
          return localStorage.getItem('token')
         ? children
         : <Redirect to="/"/> 
      }}/>
    )
  }
  return (
    <BrowserRouter>
    <Switch>
      <Route path="/" exact>
        <Login />
      </Route>
      <PrivateRoutes path="/auth2">
        < Auth2 />
      </PrivateRoutes>
      <PrivateRoutes path="/logs">
        < LogList />
      </PrivateRoutes>

      <PrivateRoutes path="/passwords">
        < PasswordList />
      </PrivateRoutes>
      <PrivateRoutes path="/add-password">
        < AddPassword />
      </PrivateRoutes>
     
      <PrivateRoutes path="/settings">
        <Settings />

        
      </PrivateRoutes>

  



      <PrivateRoutes component={NotFound}>

      </PrivateRoutes>

      </Switch>
    </BrowserRouter>
  )
}

export default ADMIN_ROLE_ROUTE