import { authApi } from "../api/Auth.api"

export const getLogsByAcccountId = async () =>{

  try {
    const response: any = await authApi.get(`/logs/account_id`)

    return response
  } catch (error) {
    console.log(error)
    return error
  }

}