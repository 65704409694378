import moment from 'moment';
import Moment from 'moment';

export interface LogData {
  data: {
    id?: string
    ip: string
    provider: string
    info: dataLog
    createdAt: string
    updatedAt: string
    accountId: string
    
  }
}

interface dataLog {
  range: []
  country: string
  region: string
  timezone: string
  city: string
  metro: string,

  
}

const LogRow = ({data}: LogData) => {

  Moment.locale('en');
  
  return (
    
    <tr>
      <td className="border-0">
        <div className="d-flex align-items-center">
          <div><span className="h6"></span>{data.ip}</div>
        </div>
      </td>
      <td className="border-0 fw-bold">{data.info.country}</td>
      <td className="border-0 text-info">
        <div className="d-flex align-items-center">
          <svg className="icon icon-xs me-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          <span className="fw-bold">{data.info.region}</span>
        </div>
      </td>
      <td className="border-0 fw-bold">
        {data.info.city}
      </td>
      <td className="border-0">
      {Moment(data.createdAt).format('lll')}
      </td>
      <td className="border-0">
      {Moment(data.updatedAt).format('lll')}
      
      </td>
      <td className="border-0 text-success">
        <div className="d-flex align-items-center">
          <svg className="icon icon-xs me-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
          <span className="fw-bold">{moment.duration(moment(data.createdAt).diff(data.updatedAt)).humanize().split("a")}</span>
        </div>
      </td>
    </tr>
  )
}

export default LogRow