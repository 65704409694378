/* eslint-disable react-hooks/rules-of-hooks */

import { useEffect, useState } from "react"

import { ErrorMessage, Field, Form, Formik } from "formik"
import * as Yup from 'yup';
import { getPasswordDecrypted } from "../../services/password/PasswordService";
import { useHistory } from "react-router";


const ModalDecodePassword = (props: any) => {

  const history = useHistory()

  const [data, useSetData] = useState<any>({})
  const [error, setError] = useState<any>('')
  const [success, setSuccess] = useState<any>({
    accountId: "",
    createdAt: "",
    href: "",
    id: "",
    password: "",
    seed: "",
    type: "",
    updatedAt: "",
    username: "",
  })

  useEffect(() => {
    if(!props.data) return
    useSetData(props.data)
  },[props.data])


  return (
    <>

<div className="col-lg-4">

<div className="modal fade" id="modal-decode" role="dialog" aria-labelledby="modal-decode" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-body p-0">
        <div className="card p-3 p-lg-4">
          <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="text-center text-md-center mb-4 mt-md-0">
            <h1 className="mb-0 h4">{data.type}</h1>
          </div>
          <Formik 
            initialValues={{

            seed: '',

        }}

    validationSchema={
      Yup.object({

          seed: Yup.string()
          .required('Required *')


      })
    }
    
    onSubmit={ async (values: any, { resetForm })=>{
      getPasswordDecrypted(data.id, values.seed).then( response => {

        if(response.passwords) {
          setError('')
          setSuccess(response.passwords)
          resetForm()
          setTimeout(() => {
            setSuccess('')

          }, 3000)
        } else {
          setSuccess('')
          setError(`Make sure you're typing the secret correctly.`)
         
        }
      
      })
        
       
    }}
    
    >
      
      <Form noValidate>

      <div className="row">
        <div className="col-md-12 mb-3">
          <div>
            <label htmlFor="seed">Secret SEED</label>
            <Field name="seed"
              className="form-control" type="password" placeholder="Enter your secret" />
               <small className="text-danger"> <ErrorMessage name='seed' /></small>
               <small className="text-danger"> {error}</small>
              
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div>
           
                {
                  success.password ? <div className="text-center">
                    <p className="text-success">Username: {success.username}</p>
                    <p className="text-success">Password: {success.password}</p>
                  </div>
                  
                  
                  :
                  <></>
                }
              

              
          </div>
        </div>
      </div>
      <div className="mt-3">
        <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save</button>
      </div>
    </Form>

    </Formik>

          

        </div>
      </div>
    </div>
  </div>
</div>

</div>
    </>
  )

}




export default ModalDecodePassword