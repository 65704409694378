/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { doLogOut } from "../../services/AuthService"
import { getLogsByAcccountId } from "../../services/logs/LogService"
import { checkinToken } from "../../services/Settings"

/* eslint-disable react/jsx-no-undef */
const ADMIN_ROLE = () => {
  const history = useHistory()

  const [lastLog, setLastLog] = useState<any>({})
  const [currenttLog, setCurrenttLog] = useState<any>({})

  useEffect(() => {

    getLogsByAcccountId().then( response  =>{
      
      if(!response.data) return
      const lastLogObg = response.data.length - 2
      const currentLog = response.data[0]
      const getLastLog = response.data[lastLogObg]
  
      setLastLog(getLastLog)
      setCurrenttLog(currentLog)
    })

  }, [setLastLog])



/*
  useEffect(() => {
    const isValidToken  = async () => {
      
      
      try {
        const token = await checkinToken()

        console.log(token)

      } catch (error:any) {
        localStorage.clear()
      }
    }
    isValidToken()
  }, [])


*/

  const logOut = async (event: any) => {

    

    const token = localStorage.getItem('token')
    if (token) {
      await doLogOut()
      return history.push('/')
    }
  }

  const getClassName = (itemName: any) => {
    return window.location.pathname === itemName ? 'nav-item active' : 'nav-item'
  }
  return (
    
    <React.Fragment>
      <nav id="sidebarMenu" className="sidebar d-lg-block bg-gray-800 text-white collapse" data-simplebar>
        <div className="sidebar-inner px-4 pt-3">
          <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
            <div className="d-flex align-items-center">
              <div className="avatar-lg me-4">
                <img src="/img/lock/logo.png" className="card-img-top rounded-circle border-white"
                  alt="Bonnie Green" />
                  
              </div>
              <div className="d-block">

                <a href="../pages/examples/sign-in.html" className="btn btn-secondary btn-sm d-inline-flex align-items-center">
                  <svg className="icon icon-xxs me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                  </svg>
                  Sign Out
                </a>
              </div>
            </div>
            <div className="collapse-close d-md-none">
              <a href="#sidebarMenu" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                aria-expanded="true" aria-label="Toggle navigation">
                <svg className="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="align-items-center text-center pb-3 py-5">
          <img src="/img/lock/logo.png"  alt="Volt Logo"  width={100}/> 
          </div>
          <Link to="/logs" className="nav-link">
              <svg className="icon icon-xs me-2" fill="none" stroke="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <span className="sidebar-text">{ currenttLog.ip }</span>
              </Link>
          <ul className="nav flex-column pt-3 pt-md-0">
            <li className="nav-item">
              <Link to="/dashboard" className="nav-link d-flex align-items-center" >
                <span className="align-items-center sidebar-icon">
                
                </span>
                
              </Link>
            </li>

     
          
            

          <li className={getClassName('/logs')}>
              <Link to="/logs" className="nav-link">
              <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path></svg>
                <span className="sidebar-text">Logs</span>
              </Link>
            </li>


            <li className={getClassName('/passwords')}>
              <Link to="/passwords" className="nav-link">
              <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"></path></svg>
                <span className="sidebar-text">Passwords</span>
              </Link>
            </li>

            <li className={getClassName('/settings')}>
              <Link to="/settings" className="nav-link">
                <span className="sidebar-icon">
                  <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                </span>
                <span className="sidebar-text">Settings</span>
              </Link>
            </li>
            <li role="separator" className="dropdown-divider mt-4 mb-3 border-gray-700 "></li>


            <li className="nav-item">
              <a onClick={logOut} className="nav-link d-flex align-items-center">
                <svg className="dropdown-icon text-white me-2 icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1">
                  </path>
                </svg>
                <span className="text-white">Logout</span>
              </a>
            </li>

           

            <li className="nav-item">
             
            </li>

          </ul>
          <small className="fixed-bottom">
              <Link to="logs"  className="d-flex align-items-center m-4">

              <svg className="dropdown-icon warning text-white me-2 icon icon-xs me-2" fill="none" stroke="yellow" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              {lastLog.ip} <br />


              </Link>

              
              </small>

              

              
        </div>
      </nav>
    </React.Fragment>
  )
}

export default ADMIN_ROLE