/* eslint-disable react-hooks/rules-of-hooks */

import { useEffect, useState } from "react"

import { ErrorMessage, Field, Form, Formik } from "formik"
import * as Yup from 'yup';
import { editPassword } from "../../services/password/PasswordService";



const ModalEditPassword = (props: any) => {



  const [data, useSetData] = useState<any>({
    href: null,
    seed: null,
    username: null,
    password: null,
    passwordConfirmation: null,
    secretKey: null,
    type: null,

  })
  const [error, setError] = useState<any>('')
  const [success, setSuccess] = useState<string>('')

  useEffect(() => {
    if(!props.data) return
    useSetData(props.data)
  },[props.data])


  return (
    <>

<div className="col-lg-4">

<div className="modal fade" id="modal-edit-password" role="dialog" aria-labelledby="modal-edit-password" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-body p-0">
        <div className="card p-3 p-lg-4">
          <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="text-center text-md-center mb-4 mt-md-0">
            <h1 className="mb-0 h4">{data.type}</h1>
          </div>
          <Formik enableReinitialize
            initialValues={{
              href: data.href,
              seed: null,
              username: data.username,
              password: null,
              passwordConfirmation: null,
              secretKey: null,
              type: data.type,

            }}

            validationSchema={
              Yup.object({
                  seed: Yup.string()
                  .required('Required *'),
                  
              })
            }
            
            onSubmit={ async (values: any, { resetForm })=>{

              
              if(values.password === '') delete values.password && delete values.passwordConfirmation


              console.log(values.password)
              if( values.password !== values.passwordConfirmation){
                return setError('Password and confirm password does not match')
              }
             
                editPassword(data.id, values).then( response => {

                  if(response.password) {
                    setError('')
                    setSuccess('Your password has been successfully encrypted!!!')
                    resetForm()
                    setTimeout(() => {
                      setSuccess('')
          
                    }, 3000)
                  } else {
                    setSuccess('')
                    setError(`Make sure you're typing the secret correctly.`)
                   
                  }
                 
                  
                })
               
               // return history.push('/passwords')
            }}
            
            >
              
              <Form noValidate>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="type">Type</label>
                    <Field name="type"
                      className="form-control"  type="text" placeholder="Enter your type" />
                      <small className="text-danger"> <ErrorMessage name='type' /></small>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="href">URL</label>
                    <Field name="href"
                      className="form-control"  type="text" placeholder="Also your link" />
                       <small className="text-danger"> <ErrorMessage name='href' /></small>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <Field name="username"
                      className="form-control" type="text" placeholder="username" />
                       <small className="text-danger"> <ErrorMessage name='username' /></small>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field name="password"
                      className="form-control" type="password" placeholder="password" />
                       <small className="text-danger"> <ErrorMessage name='password' /></small>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="passwordConfirmation">Confirm Password</label>
                    <Field name="passwordConfirmation"
                      className="form-control" type="password" placeholder="passwordConfirmation" />
                       <small className="text-danger"> <ErrorMessage name='passwordConfirmation' /></small>
                       <small className="text-danger"> {error}</small>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="seed">Secret SEED</label>
                    <Field name="seed"
                      className="form-control" type="password" placeholder="Enter your secret" />
                       <small className="text-danger"> <ErrorMessage name='seed' /></small>
                  </div>
                </div>

                <div className="col-md-12 mb-3">
          <div>
           
                {
                  success? <div className="text-center">
                    <h5 className="text-success">{success}</h5>
                  </div>
                  
                  
                  :
                  <></>
                }
              

              
          </div>
        </div>
              </div>
              <div className="mt-3">
                <button className="btn btn-gray-800 mt-2 animate-up-2" type="submit">Save</button>
              </div>
            </Form>

            </Formik>

          

        </div>
      </div>
    </div>
  </div>
</div>

</div>
    </>
  )

}

export default ModalEditPassword


