/* eslint-disable @typescript-eslint/no-unused-vars */
import { PasswordProps } from "../../props/password"




const PasswordRow: React.FC | any = (props: PasswordProps) => {
  const DEFAULT_IMAGE="img/lock/img-default-green.png"

  const navigation = (url: string) => {
    if(url.substring(0, 4) === 'http') {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      url = `http://${url}`
      window.open(url, '_blank', 'noopener,noreferrer');
    }
    
  }


  if( props.data.href.substring(0, 4) === 'http') {

   return (
    <>
    <tr>

      <td className="text-gray-900">
        {
          <img src={`${props.data.href}/favicon.ico`} alt="" width={30} height={30} 
          onError={(e: any)=>{e.target.src = DEFAULT_IMAGE}}
          onClick={()=>navigation(props.data.href)}/>
        }
       
      </td>


      <td className="text-gray-900">
        {props.data.type}
      </td>


      <td className="text-gray-900">
        {props.data.password!.slice(0, 10)+`***********************************`}
      </td>




      <td className="text-gray-900">
        {props.data.createdAt.slice(0, 10)}
      </td>
      <td className="text-gray-900">

      <svg onClick={()=>navigation(props.data.href)}
        className="icon icon-xs animate-up-2 w-5 m-0" fill="none" stroke="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
        
      </td>

      <td>

    
        <button id={'show' + props.data.id}
          className="btn btn-secondary animate-up-2 w-10 m-1" data-bs-toggle="modal" data-bs-target="#modal-decode" onClick={props.onClick}>

          <svg id={'show' + props.data.id} key={props.data.id} className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
        </button>

        <button id={'show' + props.data.id} data-bs-toggle="modal" data-bs-target="#modal-edit-password" onClick={props.onClick}
          className="btn btn-secondary animate-up-2 w-10 m-1">

          <svg id={'show' + props.data.id} onClick={props.onClick} key={props.data.id} className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
        </button>


        <button id={'show' + props.data.id} data-bs-toggle="modal" data-bs-target="#modal-delete-password" onClick={props.onClick} key={props.data.id}
          className="btn btn-danger animate-up-2 w-10 m-1" >

          <svg id={'show' + props.data.id} data-bs-toggle="modal" data-bs-target="#modal-delete-password" onClick={props.onClick}
            className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
        </button>


      </td>
    </tr>


  </>
   )
    
  } else {

    
    return (

    
      <>
        <tr>
  
          <td className="text-gray-900" onClick={()=>navigation(props.data.href)}>
            {
              <img src={`http://${props.data.href}/favicon.ico`} alt="" width={40} height={40} 
              onError={(e: any)=>{e.target.src = DEFAULT_IMAGE}}
              /> 
            }
           
            
          </td>
  
  
          <td className="text-gray-900">
            {props.data.type}
          </td>
  
  
          <td className="text-gray-900">
            {props.data.password!.slice(0, 10)+`***********************************`}
          </td>
  
  
  
    
          <td className="text-gray-900">
            {props.data.createdAt.slice(0, 10)}
          </td>

          <td className="text-gray-900">
          <svg onClick={()=>navigation(props.data.href)}
        className="icon icon-xs animate-up-2 w-5 m-0" fill="none" stroke="green" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
    
         </td>
  
          <td>
  
            <button id={'show' + props.data.id}
              className="btn btn-secondary animate-up-2 w-10 m-1" data-bs-toggle="modal" data-bs-target="#modal-decode" onClick={props.onClick}>
  
              <svg id={'show' + props.data.id} key={props.data.id} className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
            </button>
  
            <button id={'show' + props.data.id} data-bs-toggle="modal" data-bs-target="#modal-edit-password" onClick={props.onClick}
              className="btn btn-secondary animate-up-2 w-10 m-1">
  
              <svg id={'show' + props.data.id} onClick={props.onClick} key={props.data.id} className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
            </button>
  
  
            <button id={'show' + props.data.id} data-bs-toggle="modal" data-bs-target="#modal-delete-password" onClick={props.onClick} key={props.data.id}
              className="btn btn-danger animate-up-2 w-10 m-1" >
  
              <svg id={'show' + props.data.id} data-bs-toggle="modal" data-bs-target="#modal-delete-password" onClick={props.onClick}
                className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
            </button>
  
  
          </td>
        </tr>
  
  
      </>
  
  
  
  
    )
  }
  
}

export default PasswordRow