import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { doLogOut } from "../services/AuthService";
import { checkinToken } from "../services/Settings";


import ADMIN_ROLE_ROUTE from "./ADMIN_ROLE";



const Routes = () => {

  const history = useHistory()
  useEffect(() => {
    const isValidToken  = async () => {
      
      
      try {
        const token = await checkinToken()

        console.log('Token',token)

      } catch (error:any) {

        await doLogOut()
        localStorage.clear()
        return history.push('/login')
      }
    }
    //isValidToken()
  }, [])

  return (
    // (chekinRole !== 'USER_ROLE') 
    //? 
    <ADMIN_ROLE_ROUTE />
    //:
    // <USER_ROLE_ROUTE role={chekinRole}/>
    
  )
}

export default Routes