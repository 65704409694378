/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/rules-of-hooks */
import Environment from '../environments'
import { authApi } from './api/Auth.api'
import { SwalError, SwalSuccess } from './swal'



interface Props {
  email: string
  password: string
}
const url = Environment.apiUrl


export const doLogin = async ({email, password}: Props) => {
  const { data } = await authApi.post(`${url}/auth/login`, {
    email,
    password
  })

  console.log('=========================> LOGIN: ', data)

  return data
}

export const doAuth2 = async (code: string) => {


  try {
    
    const {data} = await authApi.get(`/auth/${code}`)

      localStorage.clear()
      localStorage.setItem('token', data.token)
      localStorage.setItem('accountId', data.account.id)
      localStorage.setItem('groupId', data.account.groupId)

      SwalSuccess({ title: 'Login ....' })

    return data
  } catch (error) {
    

    setTimeout(()=>{
     
      localStorage.clear()
      location.reload()
      
    }, 1 * 60 * 1000)
    return SwalError({ title: `Code not valid.` })

    
  }



}

export const doLogOut = async() => {
  try {
    const accountId = localStorage.getItem('accountId')
    const { data } = await authApi.post(`${url}/auth/logout/${accountId}`)

    localStorage.removeItem('token')
    localStorage.removeItem('accountId')
    localStorage.removeItem('groupId')  
    
    return data
  } catch (error) {
    
    return error
  }
}

